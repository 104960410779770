<template>
  <div class="note-editor routine-note-editor">
    <div class="container" style="display:flex; justify-content: center; align-items: center;">
      <b-button @click="showPreviousScores = true" v-if="d_previousScores != null && d_previousScores.length > 0 /*&& $slots.previousScores*/">{{ $t("note-editor.show-previous-scores-button") }}</b-button>
     </div>
    <div class="container wheeler-container">
      <wheeler-note-100 
        v-model="noteEntryE">
        <capitalize class="note-entry">{{ $t('note-editor.routine.execution') }}</capitalize>
      </wheeler-note-100>

      <wheeler-note-100
        v-model="noteEntryC">
        <capitalize class="note-entry">{{ $t('note-editor.routine.content') }}</capitalize>
      </wheeler-note-100>
    </div>

    <div class="container wheeler-container penalties-list">{{ penaltiesValue }}</div>

    <div style="display:flex; justify-content: center; margin-top:5px;">
      <b-button variant="warning" size="xl" @click="showPenalties=true">
        {{ $t("note-editor.details_and_penalties") }}
      </b-button>
    </div>

    <div style="display:flex; justify-content: center; ">
      <b-button
          class="endNoteEntry cancel"
          size="xs"
          variant="secondary"
          @click="cancel_click"
      >
          {{ disabled ? $t("button.back") : $t("button.cancel") }}
      </b-button>
      <b-button
          v-if='!disabled'
          class="endNoteEntry validate"
          size="xs"
          :variant="noteIsValid || penaltiesSet.zero ? 'primary' : 'danger'"
          :disabled="!noteIsValid && !penaltiesSet.zero && noteEntryE != 0 && noteEntryC != 0"
          @click="validate_click"
      >
        {{ $t("button.validate")}}
      </b-button>
    </div>

    <b-modal
      :title="$t('note-editor.details_and_penalties')"
      centered
      static
      lazy
      size="xs"
      v-model="showPenalties"
      :scrollable="true"
      class="details-and-penalties"
    >
      <template #modal-footer="{cancel}">
        <b-button size="xs" variant="primary" @click="cancel(); showPenalties=false;">
          {{$t("button.close")}}
        </b-button>
      </template>

      <routine-note-details-editor
        v-model="cartouche"
        :executionPanelVisible="executionVisible"
        :contentPanelVisible="contentVisible"
        :commentsPanelVisible="commentsVisible"
      >
        <template #between-content-and-comments>
          <collapse-panel class="cartouche" :title="$t('note-editor.penalties')" :visible="penaltiesVisible" @expand="penaltiesVisible=true" @collapse="penaltiesVisible=false">
            <Routine-penalties-editor
                v-model="penaltiesSet"
                @change="penalties_change"
            />
          </collapse-panel>
        </template>
      </routine-note-details-editor>

        <collapse-panel :title="$t('note-editor.my_default_settings')" v-if="false">
          <checkbox-form-field class="settings" :caption="$t('note-editor.settings.execution_pane_expanded')" switch v-model="executionVisible" />
          <checkbox-form-field class="settings" :caption="$t('note-editor.settings.content_pane_expanded')" switch v-model="contentVisible" />
          <checkbox-form-field class="settings" :caption="$t('note-editor.settings.penalties_pane_expanded')" switch v-model="penaltiesVisible" />
          <checkbox-form-field class="settings" :caption="$t('note-editor.settings.notes_pane_expanded')" switch v-model="commentsVisible" />
        </collapse-panel>
    </b-modal>

    <b-modal
      :title="$t('note-editor.confirm_entries')"
      centered
      :static="true"
      :lazy="true"
      size="xs"
      v-if="cfgConfirmNoteEntryByPopup || noteEntryE == 0 || noteEntryC == 0"
      v-model="showConfirmationModal"
      button-size="lg"
      class="confirm-notes-entry"
    >
      <template #modal-footer="{cancel}">
          <b-button size="sm" variant="warning" @click="cancel()">
            {{ $tc("note-editor.change_my_entries", 2) }}
          </b-button>
          <b-button size="lg" variant="success" @click="validate_click">
            {{$t("button.yes")}}
          </b-button>
      </template>
      {{ $tc("note-editor.entries_made", 2) }}
      <ul class="noteCheck">
          <li>{{ $t("note-editor.routine.execution") }} : <b>{{ noteEntryE }}</b></li>
          <li>{{ $t("note-editor.routine.content") }} : <b>{{ noteEntryC }}</b></li>
      </ul>
      
      <div v-if="penaltiesValue != ''">{{ confirmDialog_penaltiesText }} : 
          <ul v-if="penaltiesValue.split(',').length > 1" style="display:flex; flex-direction: column; justify-content:center; font-weight:bolder; font-size: larger; color: var(--ffvlDanger)">
              <li v-for="penalty in penaltiesValue.split(',')" :key='penalty'>{{ penalty }}</li>
          </ul>
          <div v-else style="display:flex; justify-content:center; font-weight: bolder; font-size: larger; color: var(--ffvlDanger)">
              {{ penaltiesValue }}
          </div>
      </div>
      <div>{{ confirmDialog_endQuestion }}</div>
      <i class="warning"><uppercase>{{ $t("common.warning") }}</uppercase> {{ confirmDialog_warningText }}</i>
    </b-modal>

    <b-modal
      v-model="showPreviousScores"
      centered
      static
      :title="$t('note-editor.previous_scores')"
      size="xs"
    >
      <template #modal-footer>
          <b-button size="xs" variant="secondary" @click="showPreviousScores=false">
            {{ $t("button.close") }}
          </b-button>  
      </template>
      <slot name="previousScores" v-bind:scores="previousScores"></slot>    
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import RoutineNoteDetailsEditor from './RoutineNoteDetailsEditor.vue';
import RoutinePenaltiesEditor from './RoutinePenaltiesEditor.vue';
import CheckboxFormField from '../Panels/CheckboxFormField.vue';
import WheelerNote100 from '../WheelerNote100.vue';
import Uppercase from '../Utilities/Uppercase.vue';
import Capitalize from '../Utilities/Capitalize.vue';

export default {
  components: { CollapsePanel, RoutinePenaltiesEditor, RoutineNoteDetailsEditor, CheckboxFormField, WheelerNote100, Uppercase, Capitalize },
    props : {
        disabled:{type:Boolean, default:false,},
        executionPound : { type: Number, default: 6},
        contentPound: {type: Number, default: 4},
        execution: { type: Number, default: null},
        content: { type: Number, default: null},
        //hasPreviousScore: { type:Boolean, default: false},
        previousScores: { type: Array, default: null },
    },
    data(){
        return {
            noteEntryE: this.execution,
            noteEntryC: this.content,
            showConfirmationModal: false,
            showPreviousScores: false,
            penaltiesSet: { version: 1, contacts: 0, total: 0, count: 0},
            showPenalties: false,
            cartouche: {
              version: 1,
            },
            //d_hasPreviousScore: this.hasPreviousScore,
            d_previousScores: this.previousScores || [],
        };
    },
    computed:{
      ...mapGetters('config', [ 'cfgRoutineNoteEditor_executionPaneVisible', 'cfgRoutineNoteEditor_contentPaneVisible', 'cfgRoutineNoteEditor_penaltiesPaneVisible', 'cfgRoutineNoteEditor_commentsPaneVisible']),

      executionVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_executionPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_executionPaneVisible(newVal); }
        },
        contentVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_contentPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_contentPaneVisible(newVal); }
        },
        penaltiesVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_penaltiesPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_penaltiesPaneVisible(newVal); }
        },
        commentsVisible:{
            get: function(){ return this.cfgRoutineNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgRoutineNoteEditor_commentsPaneVisible(newVal); }
        },
      noteIsValid(){
            return this.penaltiesSet.zero || (( this.noteEntryE != null && this.noteEntryE != "") && (this.noteEntryC != null && this.noteEntryC != ""));
        },
        confirmDialog_penaltiesText(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return '';
          if(this.penaltiesSet.count == 1)
            return "Et la pénalité suivante";
          return "Et les pénalités suivantes";
        },
        confirmDialog_endQuestion(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return "Confirmez-vous ces notes ?";
          else if(this.penaltiesSet.count == 1)
            return "Confirmez-vous ces notes et cette pénalité ?";
          return "Confirmez-vous ces notes et ces pénalités ?";
        },
        confirmDialog_warningText(){
          if(this.penaltiesSet == null || this.penaltiesSet.count == 0)
            return "Une fois confirmées, vos notes ne pourront plus être modifiées (ni aucune pénalité ajoutée).";
          else if(this.penaltiesSet.count == 1)
            return "Une fois confirmées, vos notes et la pénalité posée ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
          return "Une fois confirmées, vos notes et les pénalités posées ne pourront plus être modifiées (ni aucune autre pénalité ajoutée).";
        },
        penaltiesStringArray(){
            var ret = [];

            if((this.penaltiesSet == null || this.penaltiesSet.count == 0)) return ret;

            if(this.penaltiesSet.absenceDebut) ret.push(this.$t("scoring-penalties.missing_start"));
            if(this.penaltiesSet.absenceFin) ret.push(this.$t("scoring-penalties.missing_end"));
            if(this.penaltiesSet.depassementTpsPrepa) ret.push(this.$t("scoring-penalties.overhead_preparation_time"));
            if(this.penaltiesSet.minimum_duration_violation) ret.push(this.$t("scoring-penalties.minimum_duration_violation"));
            if(this.penaltiesSet.mauvaiseFigure) ret.push(this.$t("scoring-penalties.wrong_compulsory"));
            if(this.penaltiesSet.crash) ret.push(this.$t("scoring-penalties.crash"));
            if(this.penaltiesSet.contacts) ret.push(this.$tc("scoring-penalties.hits", this.penaltiesSet.contacts));

            if(this.penaltiesSet.sortieTerrain) ret.push(this.$t("scoring-penalties.fly_across_limits"));

            if(this.penaltiesSet.maximum_duration_violation) ret.push(this.$t("scoring-penalties.maximum_duration_violation"));

            return ret;
        },
        penaltiesValue(){
          return (this.penaltiesSet == null || this.penaltiesSet.count == 0)
                ? ""
                : "".concat( this.penaltiesStringArray);
        }
    },
    methods:{
      ...mapMutations('config', [ 'setCfgRoutineNoteEditor_executionPaneVisible', 'setCfgRoutineNoteEditor_contentPaneVisible', 'setCfgRoutineNoteEditor_penaltiesPaneVisible', 'setCfgRoutineNoteEditor_commentsPaneVisible']),
       penalties_change(){
            var ret = 0;
            var cnt = 0;
            var zero = false;
            if(this.penaltiesSet.absenceDebutOuFin){ ret +=5; cnt++}
//            if(this.penaltiesSet.absenceDebut) { ret += 5; cnt++; }
//            if(this.penaltiesSet.absenceFin) { ret += 5; cnt++; }
            if(this.penaltiesSet.depassementTpsPrepa) {ret += 5; cnt++; }
            if(this.penaltiesSet.violDureeMini) { ret+= 5; cnt++; }
            if(this.penaltiesSet.crash) { ret+= 10; cnt++; }
            
            if(this.penaltiesSet.contacts && this.penaltiesSet.contacts > 0) { ret+= this.penaltiesSet.contacts; cnt++; }

            if(this.penaltiesSet.sortieTerrain) { ret+=15; cnt++; }

            this.penaltiesSet.total = ret;
            this.penaltiesSet.count = cnt;
            this.penaltiesSet.zero = zero;
       },

       validate_click(){
            if(this.cfgConfirmNoteEntryByPopup && !this.showConfirmationModal){
                this.showConfirmationModal = true;
                return true;
            }
            var numEntryE = parseInt(this.noteEntryE);
            var numEntryC = parseInt(this.noteEntryC);
            if((numEntryE == 0 || numEntryC == 0) && this.penaltiesSet.count==0 && !this.showConfirmationModal)
            {
                this.showConfirmationModal = true;
                return true;
            }

            var grossTotal = ((numEntryE * this.executionPound) + (numEntryC * this.contentPound)); 

            var total = this.penaltiesSet.zero ? 0 : grossTotal - this.penaltiesSet.total;
            if(total < 0) total = 0;
            var exec = this.penaltiesSet.zero ? 0 : numEntryE - this.penaltiesSet.total;
            if(exec < 0) exec = 0;
            var content = this.penaltiesSet.zero ? 0 : numEntryC - this.penaltiesSet.total;
            if(content < 0) content = 0

            this.$emit('change', { grossExecution: numEntryE, grossContent: numEntryC, execution: exec, content: content, grossTotal: grossTotal, total: total, penalties: this.penaltiesSet, cartouche: this.cartouche });
            this.showConfirmationModal = false;
        },
        cancel_click(){
            this.$emit('cancel');
        },
    },
    watch:{
        //hasPreviousScore(newValue){ this.d_hasPreviousScore = newValue; },
        previousScores(newValue){ this.d_previousScores = newValue; }
    }
}
</script>

<style scoped>
    modal-body{
        display: flex 1 1;
        justify-content: center;
        align-items: center;
    }
    i.warning{
        color: var(--danger);
        font-size: 0.8em;
    }
</style>
